<template>
  <section id="dashboard-analytics">
    <b-row v-if="getFinancial">
      <b-col md="4">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col cols="12" class="position-relative">
                <b-card-title class="mb-0"> إجمالي الحجوزات المؤكدة </b-card-title>

                <div class="dashboard-card">
                  <dashboard-bookings-icon />
                  <h3>{{ getFinancial.count_bookings }} حجوزات</h3>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col cols="12" class="position-relative">
                <b-card-title class="mb-0"> إجمالي إيرادات المراكز </b-card-title>
                <div class="dashboard-card">
                  <dashboard-financial-icon />
                  <h3>{{ getFinancial.total_revenue }} ريال سعودي</h3>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col cols="12" class="position-relative">
                <b-card-title class="mb-0"> اجمالي ربح المنصة </b-card-title>
                <div class="dashboard-card">
                  <dashboard-indebtedness-icon />
                  <h3>{{ getFinancial.total_revenue_platform }} ريال سعودي</h3>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col cols="12" class="position-relative">
                <b-card-title class="mb-0"> إيرادات المراكز </b-card-title>
              </b-col>
              <b-col cols="12">
                <!-- Table Container Card -->
                <b-card no-body>
                  <div class="m-2">
                    <!-- Table Top -->
                    <b-row>
                      <!-- Search -->
                      <b-col cols="12" md="12" class="px-0">
                        <div class="d-flex align-items-center justify-content-start">
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <feather-icon icon="SearchIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              v-model="filterData.search"
                              class="d-inline-block mr-1 px-0 border-radius-right-0"
                              placeholder="بــــــــــحـــــــــث..."
                              @input="applyFilter"
                            />
                            <b-button
                              class="add-main-btn"
                              variant="flat-primary"
                              @click="showPaymentModal = !showPaymentModal"
                            >
                              <add-booking-icon />
                              إضافة ســــــــنــد قـــــــــبــض
                            </b-button>
                          </b-input-group>
                        </div>
                      </b-col>
                    </b-row>
                  </div>

                  <b-table
                    v-if="getFinancial.centers"
                    :items="getFinancial.centers"
                    responsive
                    :fields="tableColumns"
                    :per-page="filterData.per_page"
                    primary-key="id"
                    show-empty
                    empty-text="لا يوجد أي بيانات"
                    class="position-relative"
                  >
                    <!-- Column: phone -->
                    <template #cell(phone)="data">
                      <p class="m-0" dir="ltr">
                        {{ data.value }}
                      </p>
                    </template>

                    <!-- Column: platform_ratio -->
                    <template #cell(platform_ratio)="data">
                      <p class="m-0" dir="ltr">%{{ data.value }}</p>
                    </template>

                    <!-- Column: total_revenue -->
                    <template #cell(total_revenue)="data">
                      <p class="m-0">{{ data.value }} ر.س</p>
                    </template>

                    <!-- Column: total_revenue_platform -->
                    <template #cell(total_revenue_platform)="data">
                      <p class="m-0">{{ data.value }} ر.س</p>
                    </template>

                    <!-- Column: remaining_amount -->
                    <template #cell(remaining_amount)="data">
                      <p class="m-0">{{ data.value }} ر.س</p>
                    </template>

                    <!-- Column: Actions -->
                    <template #cell(actions)="data">
                      <div class="text-nowrap" dir="ltr">
                        <!-- Dropdown -->
                        <b-dropdown
                          variant="link"
                          toggle-class="p-0"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="25"
                              class="align-middle text-body"
                              @click="
                                itemCenter == null
                                  ? (itemCenter = data.item)
                                  : (itemCenter =
                                      null || itemCenter == data.item
                                        ? (itemCenter = null)
                                        : (itemCenter = data.item))
                              "
                            />
                          </template>
                          <b-dropdown-item
                            :to="{
                              name: 'details-financial',
                              params: { id: data.item.center_id },
                            }"
                          >
                            <span class="align-middle ml-50">عرض التفاصيل</span>
                            <feather-icon size="20" icon="EyeIcon" />
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </template>
                  </b-table>
                  <div
                    v-if="getFinancial.centers && getFinancial.centers.length > 0"
                    class="mx-2 mb-2"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                      >
                        <label>إدخالات</label>
                        <v-select
                          v-model="filterData.per_page"
                          :dir="'rtl'"
                          :options="perPageOptions"
                          :clearable="false"
                          :searchable="false"
                          class="per-page-selector d-inline-block ml-50 mr-1"
                          style="width: 15%"
                          @input="handlePerPageChange($event)"
                        />
                        <span class="text-muted"
                          >عرض {{ 1 }} إلى {{ filterData.per_page }} من
                          {{ getTotalFinancial.totalItems }} إدخالات</span
                        >
                      </b-col>
                      <!-- Pagination -->
                      <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                      >
                        <b-pagination
                          v-model="filterData.page"
                          :total-rows="getTotalFinancial.totalItems"
                          :per-page="filterData.per_page"
                          first-number
                          last-number
                          class="mb-0 mt-1 mt-sm-0"
                          prev-class="prev-item"
                          next-class="next-item"
                          @change="handlePageChange"
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                        </b-pagination>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal v-model="showPaymentModal" title="اضافة سند قبض" hide-footer centered>
      <div class="profile">
        <div class="header-content p-0" style="min-height: auto">
          <div class="header-content-form p-0">
            <b-row class="align-items-center w-100 m-0">
              <b-col lg="12" class="px-0">
                <b-form-group
                  class="header-content-form-group mt-0 position-relative required"
                >
                  <label>المركز</label>
                  <v-select
                    v-model="financialForm.center_id"
                    :options="getAllCenters"
                    :dir="'rtl'"
                    :searchable="false"
                    label="name"
                    :reduce="(name) => name.center_id"
                    class="invoice-filter-select"
                    placeholder="اختر المركز الذي قام بالدفع "
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12" class="px-0">
                <b-form-group
                  class="header-content-form-group mt-0 position-relative required"
                >
                  <label>نوع الدفع</label>
                  <v-select
                    v-model="financialForm.payment_type"
                    :options="paymentTypes"
                    :dir="'rtl'"
                    :searchable="false"
                    class="invoice-filter-select"
                    placeholder="اختر نوع الدفع"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12" class="px-0">
                <b-form-group
                  class="header-content-form-group position-relative required"
                >
                  <label>المبلغ</label>
                  <b-form-input
                    v-model="financialForm.amount"
                    placeholder="قم بإدخال المبلغ"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12" class="px-0">
                <b-form-group
                  class="header-content-form-group position-relative required"
                >
                  <label>رقم السند</label>
                  <b-form-input
                    v-model="financialForm.deposit_number"
                    placeholder="قم بإدخال رقم السند "
                  />
                </b-form-group>
              </b-col>

              <b-col
                lg="12"
                class="my-1 px-0 d-lg-flex d-sm-contents justify-content-between"
              >
                <b-button class="filter-main-btn" @click="showPaymentModal = false">
                  إلـــــــغــــــاء
                </b-button>
                <b-button
                  :disabled="
                    financialForm.center_id == null ||
                    financialForm.amount == null ||
                    financialForm.payment_type == null ||
                    financialForm.deposit_number == null
                  "
                  class="add-main-btn"
                  @click="addFinancialFun"
                >
                  حفظ
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
  },
  data() {
    return {
      filterData: {
        status: "",
        page: 1,
        per_page: 10,
        order_by: "desc",
        search: "",
      },
      perPageOptions: [10, 25, 50, 100],
      tableColumns: [
        { key: "id", label: "#", sortable: false },
        { key: "name", label: "اسم المركز", sortable: false },
        { key: "phone", label: "رقم الهاتف", sortable: false },
        { key: "platform_ratio", label: "نسبة المنصة", sortable: false },
        { key: "count_bookings", label: "عدد الحجوزات", sortable: false },
        { key: "total_revenue", label: "إجمالي الإيرادات", sortable: false },
        { key: "total_revenue_platform", label: "اجمالي ربح المنصة", sortable: false },
        { key: "remaining_amount", label: "المبلغ المتبقي للمنصة", sortable: false },
        { key: "actions", label: " " },
      ],
      showPaymentModal: false,
      paymentTypes: ["ايداع عن طريق البنك", "دفع كاش"],
      financialForm: {
        center_id: "",
        amount: "",
        payment_type: "",
        deposit_number: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      getFinancial: "getFinancial",
      getTotalFinancial: "getTotalFinancial",
      getAllCenters: "getAllCenters",
    }),
  },
  created() {},
  methods: {
    ...mapActions({
      loadFinancial: "loadFinancial",
      loadCenters: "loadCenters",
      addFinancial: "addFinancial",
    }),
    applyFilter() {
      this.loadFinancial(this.filterData);
    },
    handlePageChange(value) {
      this.filterData.page = value;
      this.loadFinancial(this.filterData);
    },
    handlePerPageChange(value) {
      this.loadFinancial(this.filterData);
    },
    getCenters() {
      const filterData = {
        status: "",
        page: 1,
        per_page: "all",
        order_by: "desc",
        search: "",
      };
      this.loadCenters(filterData);
    },
    addFinancialFun() {
      this.addFinancial({
        center_id: this.financialForm.center_id,
        amount: this.financialForm.amount,
        payment_type: this.financialForm.payment_type,
        deposit_number: this.financialForm.deposit_number,
      }).then((res) => {
        this.showPaymentModal = false;
        this.loadFinancial(this.filterData);
        this.financialForm = {
          center_id: "",
          amount: "",
          payment_type: "",
          deposit_number: "",
        };
      });
    },
  },
  mounted() {
    this.loadFinancial(this.filterData);
    this.getCenters();
  },
};
</script>
